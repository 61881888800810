import "./fonts.css";
import "./styles.css";

import LeafygreenProvider from '@leafygreen-ui/leafygreen-provider';
import Layout from "./components/Layout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";

import Home from "./pages/Home";
import Sec from "./pages/Sec";
import Map from "./pages/Map";
import PedidoDados from "./pages/PedidoDados";
import PedidoDadosConsulta from "./pages/PedidoDadosConsulta";
import Faleconnosco from "./pages/FaleConnosco";
import Pag from "./pages/Pag";
import Art from "./pages/Art";
import ArtPub from "./pages/ArtPub";
import Agenda from "./pages/Agenda";
import Pesquisa from "./pages/Pesquisa";
import Pagina from "./pages/Pagina";
import PaginaURL from "./pages/PaginaURL";
import Destaques from "./pages/Destaques";
import Acessibilidade from "./pages/Acessibilidade";
import ShortLink from "./pages/ShortLink";
import InqueritoSatisfacao from "./pages/InqueritoSatisfacao";


function App() {

  ReactGA.initialize("G-TBSLL3LEVF");

  return (
    <LeafygreenProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/map" element={<Map />} />
            <Route path="/pedidodados" element={<PedidoDados />} />
            <Route path="/inqueritosatisfacao/:id" element={<InqueritoSatisfacao />} />
            <Route path="/pedidodados/:id" element={<PedidoDadosConsulta />} />
            <Route path="/faleconnosco" element={<Faleconnosco />} />
            <Route path="/sec/:id" element={<Sec />} />
            <Route path="/p/:id/:id2/:id3" element={<PaginaURL />} />
            <Route path="/p/:id/:id2" element={<PaginaURL />} />
            <Route path="/p/:id" element={<PaginaURL />} />
            <Route path="/pagina/:id/:id2/:id3" element={<Pag />} />
            <Route path="/pagina/:id/:id2" element={<Pag />} />
            <Route path="/pagina/:id" element={<Pag />} />
            <Route path="/art/:id/:id2/:id3/:id4" element={<Art />} />
            <Route path="/artpub/:id" element={<ArtPub />} />
            <Route path="/agenda" element={<Agenda />} />
            <Route path="/agenda/:id" element={<Agenda />} />
            <Route path="/pesquisa/:valor" element={<Pesquisa />} />
            <Route path="/pag/:id/:id2/:id3" element={<Pagina />} />
            <Route path="/pag/:id/:id2" element={<Pagina />} />
            <Route path="/pag/:id" element={<Pagina />} />
            <Route path="/destaques" element={<Destaques />} />
            <Route path="/acessibilidade" element={<Acessibilidade />} />
            <Route path="/l/:id" element={<ShortLink />} />
            <Route path="/np4" element={<Home />} />
            <Route path="/np4/*" element={<Home />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </Router>
    </LeafygreenProvider>
  );
}

export default App;
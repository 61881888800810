import React, { useState, useEffect, useCallback } from 'react';
import TextInput from '@leafygreen-ui/text-input';
import TextArea from "@leafygreen-ui/text-area";
import Toast from "@leafygreen-ui/toast";
import Checkbox from "@leafygreen-ui/checkbox";
import emailjs from "emailjs-com";
import Bdexecute from "../components/Bdexecute";
import { Link } from 'react-router-dom';
import ConfirmationModal from "@leafygreen-ui/confirmation-modal";
import { H3 } from "@leafygreen-ui/typography";
import { Alert, Space } from 'antd';
import { Select, Option } from "@leafygreen-ui/select";
import Menu from "../components/Menu";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';


const USER_ID = "y_lO8T8PfMwWa8_y1";
const TEMPLATE_ID = "template_60k6ysb";
const SERVICE_ID = "service_7r0k2yv";



function getDescription(value) {
  let description;
  
  switch (value) {
    case "1":
      description = "DGEEC - Direção";
      break;
    case "2":
      description = "DGEEC - Outras equipas";
      break;
    case "3":
      description = "Tutela da Educação";
      break;
    case "4":
      description = "Administração Pública";
      break;
    case "5":
      description = "Empresa";
      break;
    case "6":
      description = "Estudante";
      break;
    case "7":
      description = "Encarregado de Educação";
      break;
    case "8":
      description = "Instituição de Ensino Básico e/ou Secundário";
      break;
    case "9":
      description = "Instituição de Ensino Superior";
      break;
    case "10":
      description = "Instituição de Investigação e Desenvolvimento (I&D)";
      break;
    case "11":
      description = "Instituição Privada Sem Fins Lucrativos (IPSFL)";
      break;
    case "12":
      description = "Investigador/a";
      break;
    case "13":
      description = "Organismo Internacional";
      break;
    case "14":
      description = "Outro";
      break;
    default:
      description = "Não definido";
  }

  return description;
}


function MeuFormulario() {
  document.title = "Formulário pedidos de dados ou informação";
  const [toastOpen, setToastOpen] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let [numero, setNumero] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [captchaIsDone, setCaptchaIsDone] = useState(false);
  const [mostrarAlerta1, setMostrarAlerta1] = useState(false);
  const [mostrarAlerta2, setMostrarAlerta2] = useState(false);
  const [mostrarAlerta3, setMostrarAlerta3] = useState(false);
  const [mostrarAlerta4, setMostrarAlerta4] = useState(false);
  const [mostrarAlerta5, setMostrarAlerta5] = useState(false);
  const [mostrarAlerta6, setMostrarAlerta6] = useState(false);
  let [email_deebs, setEmaildeebs] = useState();
  let [email_dees, setEmaildees] = useState();
  let [email_dsectsi, setEmaildsectsi] = useState();
  let [email_outros, setEmailoutros] = useState();

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        let results = await Bdexecute("GET", "api/emailconfig");
        if (results) {
          setEmaildeebs(results[0].email_deebs);
          setEmaildees(results[0].email_dees);
          setEmaildsectsi(results[0].email_dsectsi);
          setEmailoutros(results[0].email_outros);
        }
      } catch (error) {
        console.error("Error loading initial data:", error);
      }
    };

    loadInitialData();
  }, []);

  useEffect(() => {
    let isMounted = true;
    const loadPosts = async () => {
      try {
        let results = await Bdexecute("GET", "api/lastpedidodados");
        if (isMounted) {
          let n = results[0].numero;
          n++;
          if(results[0].numero>0) {
            setNumero(n);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  
    loadPosts();
    return () => {
      isMounted = false;
    };
  });

  async function setObjid2(id) {
    if(id.length>15) {
    await enviarEmail(dadosFormulario, basicosecundario, ensinosuperior, cienciatecnologia, inovacao, sociedadeinformacao, producaocientifica, outros, numero, id, tipoutilizador);
  }
}

  async function enviarEmail(dadosFormulario, basicosecundario, ensinosuperior, cienciatecnologia, inovacao, sociedadeinformacao, producaocientifica, outros, numero, objId, tipoutilizador) {
    
    let email_list = [];

      if (basicosecundario === true) { 
        email_deebs && email_list.push(email_deebs);
      }
      if (ensinosuperior === true) { 
        email_dees && email_list.push(email_dees);
      }
      if (cienciatecnologia === true || inovacao === true || sociedadeinformacao === true || producaocientifica === true) { 
        email_dsectsi && email_list.push(email_dsectsi);
      }
      if (outros === true) { 
        email_outros && email_list.push(email_outros);
      }
    
    let email_para = email_list.join(';');

    const description = getDescription(tipoutilizador);
    
    const templateParams = {
      pedido_nome: dadosFormulario.nome,
      pedido_email: dadosFormulario.email,
      pedido_telefone: dadosFormulario.telefone,
      pedido_descricao: dadosFormulario.descricao,
      pedido_motivo: dadosFormulario.motivo,
      pedido_basicosecundario: basicosecundario,
      pedido_ensinosuperior: ensinosuperior,
      pedido_cienciatecnologia: cienciatecnologia,
      pedido_inovacao: inovacao,
      pedido_sociedadeinformacao: sociedadeinformacao,
      pedido_producaocientifica: producaocientifica,
      pedido_outro: outros,
      pedido_numero: numero,
      pedido_id: objId,
      to_email: email_para,
      pedido_tipoutilizador: description,
    };
  
    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
      .then((response) => {
        console.log("Email enviado com sucesso!", response.status, response.text);
        setToastOpen(true);
        setTimeout(() => setToastOpen(false), 5000);
        /* setTimeout(() => navigate('/'), 6000); */
      })
      .catch((error) => {
        console.error("Erro ao enviar o email:", error);
      });
  }

  const [dadosFormulario, setDadosFormulario] = useState({
    nome: '',
    email: '',
    telefone: '',
    descricao: '',
    motivo: '',
  });

  const [basicosecundario, setBasicoSecundario] = useState(false);
  const [ensinosuperior, setEnsinosuperior] = useState(false);
  const [cienciatecnologia, setCienciatecnologia] = useState(false);
  const [inovacao, setInovacao] = useState(false);
  const [sociedadeinformacao, setSociedadeinformacao] = useState(false);
  const [producaocientifica, setProducaocientifica] = useState(false);
  const [outros, setOutros] = useState(false);
  const [tipoutilizador, setTipo] = useState("");
  const [erro, setErro] = useState(" ");
  const [errostate, setErrostate] = useState("error");
  const [tipooutro, setTipooutro] = useState('');
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMostrarAlerta1(false);
    setMostrarAlerta2(false);
    setMostrarAlerta3(false);
    setMostrarAlerta4(false);
    setMostrarAlerta5(false);
    setMostrarAlerta6(false);

    setDadosFormulario({
      ...dadosFormulario,
      [name]: value,
    });
  };

  const handleChange = (valor) => {
    setTipo(valor);
    if(valor) {
      setErro("");
      setErrostate("none");
      setMostrarAlerta6(false);
    } else {
      setErro(" ");
      setErrostate("error");     
    }
  };

  const validarSelect = () => {
    if (!tipoutilizador) {
      setErro(" ");
      setErrostate("error");
      return false;
    } else {
      setErro("");
      setErrostate("none");
      return true;
    }
  };

  const inserirbd = async () => {
    const data = { dadosFormulario, basicosecundario, ensinosuperior, cienciatecnologia, inovacao, sociedadeinformacao, producaocientifica, outros, numero, tipoutilizador, tipooutro };
    let result = await Bdexecute("POST", `api/inserirpedidodados`, data);
  
    if (result) {
      try {
        let results2 = await Bdexecute("GET", "api/lastpedidodados");
        await setObjid2(results2[0]._id);
      } catch (error) {
        console.error("Erro ao obter os últimos dados do pedido:", error);
      }
    }
  };
  
  const handleSubmitConfirm = async (e) => {
    e.preventDefault();
    
    try {
      setShowModal(false);
      if (captchaIsDone) {
        await inserirbd(dadosFormulario, basicosecundario, ensinosuperior, cienciatecnologia, inovacao, sociedadeinformacao, producaocientifica, outros, numero, tipoutilizador);
        setCaptchaIsDone(false);
      }
      setSubmitting(false);
    } catch (error) {
    } finally {
      //setSubmitting(false);
    }
    
  }

  const cancelarSubmit = () => {
    setShowModal(false);
    setSubmitting(false);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (submitting) return;
    
    if (!dadosFormulario.nome) {
      setMostrarAlerta1(true);
      const nomeInput = document.getElementById('nome');
      if (nomeInput) {
        nomeInput.focus();
      }
      return;
    }

    if (validarSelect()===false) {
      setMostrarAlerta6(true);
      const tipoInput = document.getElementById('select-2');
      if (tipoInput) {
        tipoInput.focus();
      }
      return;
    }

    if (!dadosFormulario.email) {
      setMostrarAlerta2(true);
      const emailInput = document.getElementById('email');
      if (emailInput) {
        emailInput.focus();
      }
      return;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(dadosFormulario.email)) {
        setMostrarAlerta2(true);
        const emailInput = document.getElementById('email');
        if (emailInput) {
          emailInput.focus();
        }
        return;
      }
    }

    if(!basicosecundario && !ensinosuperior && !cienciatecnologia && !inovacao && !sociedadeinformacao && !producaocientifica && !outros) {
      setMostrarAlerta3(true);
      const basicosecundarioInput = document.getElementById('basicosecundarioch');
      if (basicosecundarioInput) {
        basicosecundarioInput.focus();
      }
      return;
    }

    if (!dadosFormulario.descricao) {
      setMostrarAlerta4(true);
      const descricaoInput = document.getElementById('descricao');
      if (descricaoInput) {
        descricaoInput.focus();
      }
      return;
    }

    if (!dadosFormulario.motivo) {
      setMostrarAlerta5(true);
      const motivoInput = document.getElementById('motivo');
      if (motivoInput) {
        motivoInput.focus();
      }
      return;
    }

    setSubmitting(true);
    setShowModal(true);


  };

  function emailformat(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }


  const ReCaptchaComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
  
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
  
      try {
        const token = await executeRecaptcha();
        // Enviar token ao backend para verificação
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${baseUrl}/api/verify-recaptcha`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
  
        const data = await response.json();
  
        if (data.success) {
          setCaptchaIsDone(true);
          console.log('reCAPTCHA validado com sucesso.');
        } else {
          console.log('Falha na verificação do reCAPTCHA.');
        }
      } catch (error) {
        console.error('Error executing recaptcha:', error);
      }

    }, [executeRecaptcha]);
  
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);
  
    return true;
  };
  

  return (
    <>

    <Menu />

    <div className="container-cinza">
      <div className='container' id="seccaoprincipal">
        <h1 className='h1style'>Pedido personalizado de dados/informação</h1>
        <div className='container'>
        <p className="paragrafoTexto">A consulta às áreas de atuação da DGEEC (Educação Pré-Escolar, Básico e Secundário – Ensino Superior – Ciência e Tecnologia) permite verificar toda a informação estatística disponibilizada pela DGEEC. Caso não encontre resposta às suas necessidades, pode efetuar um pedido de informação estatística personalizado preenchendo o formulário abaixo.</p>
        <p className="paragrafoTexto">Por favor, indique o seu endereço de correio eletrónico e, no caso de um pedido de informação estatística, explicite a informação que necessita, referindo, sempre que possível, o período temporal e a unidade geográfica a que se refere.</p>
        <p className="paragrafoTexto">O seu pedido será analisado e receberá uma resposta, no prazo máximo de 5 dias úteis. Caso a informação esteja sujeita a tarifação, receberá primeiro um orçamento prévio, elaborado com base no <a href="/artpub/657c356023f9758986a7d461" target="_blank" rel="noopener noreferrer">tarifário em vigor.</a> </p>
        <br />
        <div style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}>
        <form onSubmit={handleSubmit}>
        <div style={{ maxWidth: '600px'}}>
            <TextInput
              label="Nome (obrigatório)"
              baseFontSize={16}
              optional={true}
              description=""
              name="nome"
              id="nome"
              onChange={handleInputChange}
              value={dadosFormulario.nome}
              state={dadosFormulario.nome ? 'valid' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta1 && (
                <Alert message="Por favor, preencha o campo obrigatório Nome" type="error" />
              )}
            </Space>

            <Select
              align="top"
              placeholder="Selecione"
              darkMode={false}
              data-test="hello"
              defaultValue={tipoutilizador}
              description=""
              justify="start"
              label="Tipo de Utilizador (obrigatório)"
              name="tipo"
              readOnly={false}
              usePortal={true}
              onChange={handleChange}
              value={tipoutilizador}
              required={true}
              errorMessage={erro}
              state={errostate}
            >
                  <Option value="4">Administração Pública</Option>
                  <Option value="5">Empresa</Option>
                  <Option value="6">Estudante</Option>
                  <Option value="7">Encarregado de Educação</Option>
                  <Option value="8">Instituição de Ensino Básico e/ou Secundário</Option>
                  <Option value="9">Instituição de Ensino Superior</Option>
                  <Option value="10">Instituição de Investigação e Desenvolvimento (I&D)</Option>
                  <Option value="11">Instituição Privada Sem Fins Lucrativos (IPSFL)</Option>
                  <Option value="12">Investigador/a</Option>
                  <Option value="13">Organismo Internacional</Option>
                  <Option value="14">Outro</Option>
            </Select>
            {tipoutilizador === "14" && (
              <TextInput
                label="Qual?"
                description=""
                value={tipooutro}
                onChange={e => setTipooutro(e.target.value)}
              />
            )}
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta6 && (
                <Alert message="Por favor, selecione um tipo" type="error" />
              )}
            </Space>
            <br />
            <TextInput
              label="Email (obrigatório)"
              baseFontSize={16}
              type="email"
              optional={true}
              description=""
              name="email"
              id="email"
              onChange={handleInputChange}
              value={dadosFormulario.email}
              state={dadosFormulario.email && emailformat(dadosFormulario.email) ? 'valid' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta2 && (
                <Alert message="Por favor, insira um email válido" type="error" />
              )}
            </Space>

            </div>
            <div style={{ maxWidth: '300px'}}>
            <TextInput
              label="Telefone"
              baseFontSize={16}
              type="number"
              optional={true}
              description=""
              name="telefone"
              onChange={handleInputChange}
              value={dadosFormulario.telefone}
            />
            </div>
            <p>Dados a que se respeita (selecione pelo menos uma opção da lista)</p>
            <Checkbox
              description=""
              id="basicosecundarioch"
              baseFontSize={16}
              label={<span style={{ fontSize: '16px' }}>Ensino Básico e Secundário</span>}
              onChange={e => setBasicoSecundario(e.target.checked)}
              checked={basicosecundario}
            />
            <Checkbox
              description=""
              baseFontSize={16}
              label={<span style={{ fontSize: '16px' }}>Ensino Superior</span>}
              onChange={e => setEnsinosuperior(e.target.checked)}
              checked={ensinosuperior}
            />
            <Checkbox
              description=""
              baseFontSize={16}
              label={<span style={{ fontSize: '16px' }}>Ciência e Tecnologia</span>}
              onChange={e => setCienciatecnologia(e.target.checked)}
              checked={cienciatecnologia}
            />
            <Checkbox
              description=""
              label={<span style={{ fontSize: '16px' }}>Inovação</span>}
              baseFontSize={16}
              onChange={e => setInovacao(e.target.checked)}
              checked={inovacao}
            />
            <Checkbox
              description=""
              label={<span style={{ fontSize: '16px' }}>Sociedade de Informação</span>}
              baseFontSize={16}
              onChange={e => setSociedadeinformacao(e.target.checked)}
              checked={sociedadeinformacao}
            />
            <Checkbox
              description=""
              label={<span style={{ fontSize: '16px' }}>Produção Científica</span>}
              baseFontSize={16}
              onChange={e => setProducaocientifica(e.target.checked)}
              checked={producaocientifica}
            />
            <Checkbox
              description=""
              label={<span style={{ fontSize: '16px' }}>Outros</span>}
              baseFontSize={16}
              onChange={e => setOutros(e.target.checked)}
              checked={outros}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta3 && (
                <Alert message="Por favor, selecione a opção a que se respeitam os dados" type="error" />
              )}
            </Space>

            <br />

            <TextArea
              label={<span style={{ fontSize: '16px' }}>Descrição pormenorizada do pedido (obrigatório)</span>}
              baseFontSize={16}
              name="descricao"
              id="descricao"
              description=""
              onChange={handleInputChange}
              rows="6"
              optional={true}
              value={dadosFormulario.descricao}
              state={dadosFormulario.descricao ? 'none' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta4 && (
                <Alert message="Por favor, preencha o campo obrigatório Descrição" type="error" />
              )}
            </Space>
<br />
            <TextArea
              label={<span style={{ fontSize: '16px' }}>Motivo/Objetivos (obrigatório)</span>}
             baseFontSize={16}
              name="motivo"
              id="motivo"
              description=""
              onChange={handleInputChange}
              rows="6"
              optional={true}
              value={dadosFormulario.motivo}
              state={dadosFormulario.motivo ? 'none' : 'error'}
            />
            <Space direction="vertical" style={{ width: '100%' }}>
              {mostrarAlerta5 && (
                <Alert message="Por favor, preencha o campo obrigatório Motivos ou Objetivos" type="error" />
              )}
            </Space>

            <GoogleReCaptchaProvider reCaptchaKey="6LftQ74pAAAAANYLLaWGmpE63XJeOvoAjwoQb3L4">
            <ReCaptchaComponent />
          </GoogleReCaptchaProvider>

            <p className="paragrafoTexto">A informação pessoal que consta neste formulário está protegida de divulgação, sendo utilizada exclusivamente para efeitos de tratamento do seu pedido e para o envio de um questionário pós-serviço (de resposta facultativa). Este questionário tem como objetivo avaliar o serviço que prestamos e permitir-nos-á implementar ações que melhorem a qualidade dos nossos produtos e do nosso serviço.</p>
            <p className="paragrafoTexto">Para mais informação consulte a <a href="/artpub/657364565f39ee77721e9c09" target="_blank" rel="noopener noreferrer">Política de Privacidade</a> da DGEEC.</p>
            <br />
            <p className='center-button'>
            <Link className='buttonSecundario' to="#" onClick={() => window.history.back()}>VOLTAR</Link>

            {captchaIsDone ? (
                <button className='buttonEnviar' disabled={submitting} type="submit">
                  {submitting ? 'A ENVIAR...' : 'ENVIAR'}
                </button>
              ) : (
                <button className='buttonEnviarDisabled' disabled={true} type="submit">
                  ENVIAR
                </button>
              )}

            </p>
          </form>
          </div>

      <ConfirmationModal
        open={showModal}
        buttonText="Enviar"
        onConfirm={handleSubmitConfirm}
        onCancel={cancelarSubmit}
        variant="primary"
        title="Confirmar"
        >
              
              <H3>Tem a certeza que deseja enviar o seu pedido de dados com os dados registados no formulário?</H3>

      </ConfirmationModal>


          <Toast
            variant="success"
            title="Pedido personalizado de dados"
            body="Pedido registado com sucesso."
            open={toastOpen}
            close={() => setToastOpen(false)}
          />
        </div>
      </div>
    </div>
    </>
  );
}

export default MeuFormulario;


import React from "react";
import Card from "@leafygreen-ui/card";
import { css } from "@leafygreen-ui/emotion";
import { H3 } from "@leafygreen-ui/typography";
import Tag from "./Tag"

const cardStyle = css`
  border-radius: 0px !important;
  margin: 0.5em;
  min-height: 160px;
  box-shadow: none !important;

  &:hover {
    box-shadow: 0 4px 10px -4px rgba(0, 30, 43, 0.3) !important;
    background-color: #f8f8f8;
  }
`

export default function PostSummary(props) {
  const optionsdate = { day: 'numeric',  month: 'long', year: 'numeric' };
  return (
    <>
      {props.title !== undefined ? (
        <a href={`/artpub/${props._id}`}>
          <Card className={cardStyle}>
            {props.imagem && (
              <div
                style={{
                  float: 'left',
                  width: '200px',
                  height: '100px',
                  margin: '10px',
                  backgroundSize: 'cover',
                  backgroundImage: `url('${props.imagem ? props.imagem.replace(/ /g, "%20") : ""}')`
                }}
              ></div>
            )}
            {props.title && <H3>{props.title}</H3>}
            {props.datapublicacao && (
              <>
                {(new Date(props.datapublicacao)).toLocaleDateString('pt-PT', optionsdate)}
                <br />
              </>
            )}
            <Tag valor={props.tags} />
            <p className="direita">Consultar artigo</p>
          </Card>
        </a>
      ) : (
        <a href={`/artpub/${props.link}`}>
          <Card className={cardStyle}>
            {props.imagem && (
              <div
                style={{
                  float: 'left',
                  width: '200px',
                  height: '100px',
                  margin: '10px',
                  backgroundSize: 'cover',
                  backgroundImage: `url('${props.imagem ? props.imagem.replace(/ /g, "%20") : ""}')`
                }}
              ></div>
            )}
            {(props.titleano || props.designacao) && <H3>{props.titleano || props.designacao}</H3>}
            {props.datapublicacao && (
              <>
                {(new Date(props.datapublicacao)).toLocaleDateString('pt-PT', optionsdate)}
                <br />
              </>
            )}
            <p className="direita">Consultar artigo</p>
          </Card>
        </a>
      )}
    </>
  );
  
}
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import TextArea from "@leafygreen-ui/text-area";
import { RadioBoxGroup, RadioBox } from '@leafygreen-ui/radio-box-group';
import Toast from "@leafygreen-ui/toast";
import emailjs from "emailjs-com";
import { Link, useParams, useNavigate } from 'react-router-dom';
import ConfirmationModal from "@leafygreen-ui/confirmation-modal";
import { H2, H3 } from "@leafygreen-ui/typography";
import Menu from "../components/Menu";
import Bdexecute from "../components/Bdexecute";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

const USER_ID = "y_lO8T8PfMwWa8_y1";
const TEMPLATE_ID = "template_ko9lc5e";
const SERVICE_ID = "service_7r0k2yv";


function MeuFormulario() {
  document.title = "Inquérito de Satisfação";
  let params = useParams();
  const navigate = useNavigate();
  const [toastOpen, setToastOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  let [showModal, setShowModal] = useState(false);
  const [captchaIsDone, setCaptchaIsDone] = useState(false);
  const [inqsatisfacao, setInqsatisfacao] = useState({});
  const [lblglobal, setLblglobal] = useState('Sem resultado');
    let [email_deebs, setEmaildeebs] = useState();
    let [email_dees, setEmaildees] = useState();
    let [email_dsectsi, setEmaildsectsi] = useState();
    let [email_outros, setEmailoutros] = useState();

    useEffect(() => {
      const loadInitialData = async () => {
        try {
          let results = await Bdexecute("GET", "api/emailconfig");
          if (results) {
            setEmaildeebs(results[0].email_deebs);
            setEmaildees(results[0].email_dees);
            setEmaildsectsi(results[0].email_dsectsi);
            setEmailoutros(results[0].email_outros);
          }
        } catch (error) {
          console.error("Error loading initial data:", error);
        }
      };
  
      loadInitialData();
    }, []);

  function enviarEmail() {

    let email_list = [];

    if (dadosFormulario.basicosecundario === true) { 
      email_deebs && email_list.push(email_deebs);
    }
    if (dadosFormulario.ensinosuperior === true) { 
      email_dees && email_list.push(email_dees);
    }
    if (dadosFormulario.cienciatecnologia === true || dadosFormulario.inovacao === true || dadosFormulario.sociedadeinformacao === true || dadosFormulario.producaocientifica === true) { 
      email_dsectsi && email_list.push(email_dsectsi);
    }
    if (dadosFormulario.outros === true) { 
      email_outros && email_list.push(email_outros);
    }
    
    let email_para = email_list.join(';');
    
    const templateParams = {
      subject: "Inquérito Safisfação",
      to_name: "",
      to_email: email_para,
      message: "Foi enviado um novo  inquérito de satisfação",
      linha1: "Satisfação Global: " + lblglobal,
      linha2: "Comentário: ",
      linha3: comentario
    };
  
    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
      .then((response) => {
        console.log("Email enviado com sucesso!", response.status, response.text);
        setToastOpen(true);
        setTimeout(() => setToastOpen(false), 5000);
        setTimeout(() => navigate('/'), 6000);
      })
      .catch((error) => {
        console.error("Erro ao enviar o email:", error);
      });
  };

  const [dadosFormulario, setDadosFormulario] = useState({});
  const [datapedido, setDatapedido] = useState();
  const [idpedido, setIdpedido] = useState();
  const [satisfacaoglobal, setSatisfacao] = useState();
  const [comentario, setComentario] = useState();
  const [respostaexistente, setRespostaexistente] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const loadPosts = async () => {
      try {
        let results = await Bdexecute("GET", `api/pedidodados/${params.id}`);
        if (isMounted) {
          setDadosFormulario(results);
          setDatapedido(results.date);
          setIdpedido(results._id);
        }
      } catch (error) {
        console.error(error);
      }
    }

    const loadPosts2 = async () => {
      try {
        let results = await Bdexecute("GET", `api/respostaexistente/${params.id}`);
        if (isMounted) {
          setRespostaexistente(results);
        }
      } catch (error) {
        console.error(error);
      }
    }

    loadPosts();
    loadPosts2();

    return () => {
      isMounted = false;
    };
  }, [params.id]);




  const handleSubmit = async (e) => {
    e.preventDefault();

    if (submitting) return;
    setShowModal(true);
    setSubmitting(true);
  };

  const inserirbd = async () => {
    const data = { idpedido, satisfacaoglobal, comentario, ...inqsatisfacao };
    let result = await Bdexecute("POST", `api/inseririnquerito`, data);
  
    if (result) {
       
    }
  };

  const handleSubmitConfirm = async (e) => {
    e.preventDefault();

    try {
      setShowModal(false);
        if (captchaIsDone) {
          await inserirbd();
          await enviarEmail();
          setCaptchaIsDone(false);
        }
      //setSubmitting(false);
      
    } catch (error) {
    } finally {
      //setSubmitting(false);
    }
  }


  const cancelarSubmit = () => {
    setShowModal(false);
    setSubmitting(false);
  }


    const ReCaptchaComponent = () => {
      const { executeRecaptcha } = useGoogleReCaptcha();
    
      // Create an event handler so you can call the verification on button click event or form submit
      const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }
    
        try {
          const token = await executeRecaptcha();
          // Enviar token ao backend para verificação
          const baseUrl = process.env.REACT_APP_BASE_URL;
          const response = await fetch(`${baseUrl}/api/verify-recaptcha`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });
    
          const data = await response.json();
    
          if (data.success) {
            setCaptchaIsDone(true);
            console.log('reCAPTCHA validado com sucesso.');
          } else {
            console.log('Falha na verificação do reCAPTCHA.');
          }
        } catch (error) {
          console.error('Error executing recaptcha:', error);
        }

      }, [executeRecaptcha]);
    
      useEffect(() => {
        handleReCaptchaVerify();
      }, [handleReCaptchaVerify]);
    
      return true;
    };

    let questions = [];
    const existeValorCamentoMaiorQueZero = dadosFormulario.respostas?.some((resposta) => resposta.valororcamento > 0);

    if (existeValorCamentoMaiorQueZero) {
      questions = [
        "Adequação da resposta",
        "Apoio e esclarecimento técnico",
        "Clareza da resposta",
        "Tempo de resposta",
        "Comunicação sobre atrasos no fornecimento da informação",
        "Relação preço/qualidade (informação tarifada)",
      ];
    } else {
      questions = [
        "Adequação da resposta",
        "Apoio e esclarecimento técnico",
        "Clareza da resposta",
        "Tempo de resposta",
        "Comunicação sobre atrasos no fornecimento da informação",
      ];
    }
    

  
    const options = useMemo(() => [
      { id: "1", label: "Muito Insatisfeito", value: 1 },
      { id: "2", label: "Insatisfeito", value: 2 },
      { id: "3", label: "Satisfeito", value: 3 },
      { id: "4", label: "Muito Satisfeito", value: 4 },
      { id: "0", label: "Não Aplicável", value: 0 },
    ], []);

    // Função para calcular a média
    const calculateAverage = useCallback((options) => {
      const filteredKeys = Object.keys(inqsatisfacao).filter((key) => {
        const id = parseInt(key, 10);
        return id >= 0 && id <= 5; // Verifica se o ID está no intervalo 0-5
      });

        const values = filteredKeys.map((key) => inqsatisfacao[key]).filter((val) => val > 0);
        if (values.length === 0) return "N/A";
        const sum = values.reduce((acc, val) => acc + val, 0);
        const resultado = (sum / values.length).toFixed(2);
        setSatisfacao(resultado);
        const processedResult = Math.round(resultado);
        const lbl = options.find((opt) => opt.id === String(processedResult))?.label || "-";
        setLblglobal(lbl);
      }, [inqsatisfacao]); 

    useEffect(() => {
      calculateAverage(options);
    }, [inqsatisfacao,calculateAverage,options]);
  
  return (
    <>
    <Menu />
    <div className="container-cinza">
      <div className='container'>
        <h1 className='h1style'>Inquérito de satisfação dos utilizadores de informação estatística</h1>
        <div className='container' id="seccaoprincipal">
        {
            respostaexistente!==false ? (
              <p className="paragrafoTexto">Resposta ao inquérito já submetida. Obrigado!</p>
            ) : (
              <>
              <p className="paragrafoTexto">
                Assinale o seu grau de satisfação relativamente a cada um dos aspetos relacionados com o seu pedido efetuado em {(new Date(datapedido)).toLocaleDateString()}
              </p>

          <div style={{ maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }}>
          <form onSubmit={handleSubmit}>

          {questions.map((question, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <h3>{question}</h3>
              <RadioBoxGroup
                name={`question-${index}`}
                onChange={(e) => {
                  const value = e.target.value;
                  const selectedOption = options.find((opt) => opt.id === String(value));
                  setInqsatisfacao((prev) => ({
                    ...prev,
                    [index]: selectedOption ? selectedOption.value : 0,
                  }));
                }}
                
              >
                {options.map((option) => (
                  <RadioBox key={option.id} value={option.id}>
                    {option.label}
                  </RadioBox>
                ))}
              </RadioBoxGroup>
            </div>
          ))}

            <br />

            <TextArea
              label={<span style={{ fontSize: '16px' }}>Comentários/Sugestões</span>}
              baseFontSize={16}
              name="comentario"
              id="comentario"
              description=""
              optional={true}
              onChange={e => setComentario(e.target.value)}
              rows="6"
              value={comentario}
            />

            <GoogleReCaptchaProvider reCaptchaKey="6LftQ74pAAAAANYLLaWGmpE63XJeOvoAjwoQb3L4">
            <ReCaptchaComponent />
          </GoogleReCaptchaProvider>

            <br />
            <p className='center-button'>
            <Link className='buttonSecundario' to="#" onClick={() => window.history.back()}>VOLTAR</Link>
            {captchaIsDone ? (
                <button className='buttonEnviar' disabled={submitting} type="submit">
                  {submitting ? 'A ENVIAR...' : 'ENVIAR'}
                </button>
              ) : (
                <button className='buttonEnviarDisabled' disabled={true} type="submit">
                  ENVIAR
                </button>
              )}

            </p>
          </form>
          </div>
          </>
        )
      }

          <ConfirmationModal
        open={showModal}
        buttonText="Enviar"
        onConfirm={handleSubmitConfirm}
        onCancel={cancelarSubmit}
        variant="primary"
        title="Confirmar"
        >
              <H2>Resultado global:  {lblglobal}</H2>
              <br />
              <H3>Tem a certeza que deseja enviar a sua opinião com os dados registados no formulário?</H3>

      </ConfirmationModal>

          <Toast
            variant="success"
            title="Inquérito de Satisfação"
            body="Obrigado por nos enviar a sua opinião."
            open={toastOpen}
            close={() => setToastOpen(false)}
          />


        </div>
      </div>
    </div>
    </>
  );
}

export default MeuFormulario;


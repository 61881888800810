import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TextArea from "@leafygreen-ui/text-area";
import Checkbox from "@leafygreen-ui/checkbox";
import Bdexecute from "../components/Bdexecute";
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.locale("pt");

const { Panel } = Collapse;

function traduzirEstado(estado) {
  switch (estado) {
    case '10':
      return "Rejeitado";
    case '20':
      return "Em análise";
    case '30':
      return "Orçamentado";
    case '40':
      return "Em preparação";
    case '50':
      return "Preparado";
    case '60':
      return "Respondido";
    case '70':
      return "Anulado";
    default:
      return "";
  }
}

function MeuFormulario() {
  let params = useParams();
  let [pedidodados, setPedidoDados] = useState([]);
  let [respostas, setRespostas] = useState([]);

  const [dadosFormulario, setDadosFormulario] = useState({
    nome: '',
    email: '',
    telefone: '',
    descricao: '',
    motivo: '',
  });

  const [basicosecundario, setBasicoSecundario] = useState(false);
  const [ensinosuperior, setEnsinosuperior] = useState(false);
  const [cienciatecnologia, setCienciatecnologia] = useState(false);
  const [inovacao, setInovacao] = useState(false);
  const [sociedadeinformacao, setSociedadeinformacao] = useState(false);
  const [producaocientifica, setProducaocientifica] = useState(false);
  const [outros, setOutros] = useState(false);
  const [numero, setNumero] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const loadPosts = async () => {
      try {
        let results = await Bdexecute("GET", `api/pedidodados/${params.id}`);
        if (isMounted) {
          setPedidoDados(results);
        }
      } catch (error) {
        console.error(error);
      }
    }
    loadPosts();

    return () => {
      isMounted = false;
    };
  }, [params.id]);


  useEffect(() => {
    if (!pedidodados._id) { return }
    setDadosFormulario(pedidodados.dadosFormulario);
    setBasicoSecundario(pedidodados.basicosecundario);
    setEnsinosuperior(pedidodados.ensinosuperior);
    setCienciatecnologia(pedidodados.cienciatecnologia);
    setInovacao(pedidodados.inovacao);
    setSociedadeinformacao(pedidodados.sociedadeinformacao);
    setProducaocientifica(pedidodados.producaocientifica);
    setOutros(pedidodados.outros);
    setNumero(pedidodados.numero);
    if(pedidodados.respostas) { setRespostas(pedidodados.respostas.sort((a, b) => dayjs(a.dataartigo).isAfter(dayjs(b.dataartigo)) ? 1 : -1)) };
  }, [pedidodados]);

  return (
    <div className="container-cinza">
      <div className='container'>
        <h1 className='h1style'>Pedido personalizado de dados/informação</h1>
        <div className='container'>
        <p><strong>Número {numero}</strong></p>
            <p>Dados a que se respeita</p>
            <Checkbox
              description=""
              label="Ensino Básico e Secundário"
              checked={basicosecundario}
            />
            <Checkbox
              description=""
              label="Ensino Superior"
              checked={ensinosuperior}
            />
            <Checkbox
              description=""
              label="Ciência e Tecnologia"
              checked={cienciatecnologia}
            />
            <Checkbox
              description=""
              label="Inovação"
              checked={inovacao}
            />
            <Checkbox
              description=""
              label="Sociedade de Informação"
              checked={sociedadeinformacao}
            />
            <Checkbox
              description=""
              label="Produção Científica"
              checked={producaocientifica}
            />
            <Checkbox
              description=""
              label="Outros"
              checked={outros}
            />

            <br />

            <TextArea
              label="Descrição pormenorizada do pedido"
              name="descricao"
              description=""
              rows="6"
              value={dadosFormulario.descricao}
            />

            <TextArea
              label="Motivo/Objetivos"
              name="motivo"
              description=""
              rows="6"
              value={dadosFormulario.motivo}
            />
            <br />
        <Collapse accordion>
        <Panel header='Registado' key='hf78th348h8u3r8h34t8938t' extra={dayjs(pedidodados.date).format('DD-MM-YYYY')}>
        </Panel>
        {respostas && respostas.length > 0 && respostas.map((artigo) => (
                <Panel header={traduzirEstado(artigo.estado)} key={artigo._id} extra={dayjs(artigo.dataartigo, 'YYYY-MM-DD').format('DD-MM-YYYY')}>
            </Panel>
        ))}
        </Collapse>
        </div>
      </div>
    </div>
  );
}

export default MeuFormulario;

